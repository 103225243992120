import React from 'react';
import PropTypes from 'prop-types';
import { CssBaseline } from '@material-ui/core';
import {
  createTheme,
  ThemeProvider,
  StylesProvider,
  jssPreset,
} from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { isRtl } from './../../../helpers/locales';
import vbTheme from './base-theme';

const Theme = ({ children }) => {
  const intl = useIntl();
  const direction = isRtl(intl.locale) ? 'rtl' : 'ltr';

  const themeWithDirection = createTheme({
    ...vbTheme,
    direction,
  });

  // Configure JSS
  const jss = create({
    plugins: [...jssPreset().plugins, direction === 'rtl' ? rtl() : null],
  });

  const StylesDirection = (props) => {
    return <StylesProvider jss={jss}>{props.children}</StylesProvider>;
  };

  return (
    <StylesDirection>
      <ThemeProvider theme={themeWithDirection}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StylesDirection>
  );
};

Theme.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Theme;
