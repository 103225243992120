import { gql } from '@apollo/client';

const UPDATE_USER_NEWLETTERS_SUBSCRIPTIONS = gql`
  mutation updateUserNewsletterSubscriptions(
    $input: [UserNewsletterSubscriptionInput]
  ) {
    updateUserNewsletterSubscriptions(input: $input) {
      ... on UserNewsletter {
        status
        statusCode
        list_id
        name
      }
      ... on UserNewsletterError {
        status
        statusCode
        list_id
        name
      }
    }
  }
`;

export default UPDATE_USER_NEWLETTERS_SUBSCRIPTIONS;
