import { gql } from '@apollo/client';

const DELETE_USER = gql`
  mutation deleteUser($confirmDelete: Boolean!) {
    deleteUser(confirmDelete: $confirmDelete) {
      confirmDelete
    }
  }
`;

export default DELETE_USER;
