import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/remote-config';

const authDomain =
  process.env.NODE_ENV === 'production'
    ? window.location.host
    : process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

// Init Firebase
firebase.initializeApp(firebaseConfig);

// Setup context to expose firebase instance
const FirebaseContext = React.createContext();

const FirebaseContextProvider = ({ children }) => {
  const defaultState = {
    firebase,
  };

  const [firebaseState] = useState(defaultState);

  return (
    <FirebaseContext.Provider value={firebaseState}>
      {children}
    </FirebaseContext.Provider>
  );
};

FirebaseContextProvider.propTypes = {
  children: PropTypes.node,
};

const FirebaseContextConsumer = FirebaseContext.Consumer;
const useFirebase = () => useContext(FirebaseContext);

export default FirebaseContext;
export { FirebaseContextConsumer, FirebaseContextProvider, useFirebase };
