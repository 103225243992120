import { gql } from '@apollo/client';

const CREATE_LOAN = gql`
  mutation createLoan($ebookid: String!, $countryCode: String!) {
    createLoan(ebookid: $ebookid, countryCode: $countryCode) {
      id
      title
      downloadurl
      internalOrderNumber
      retailerOrderNumber
    }
  }
`;

export default CREATE_LOAN;
