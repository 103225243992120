import { gql } from '@apollo/client';

const ADD_READING_REQUEST = gql`
  mutation ReadingRequest($retailerOrderNumber: String!, $ebookid: String!) {
    addReadingRequest(
      retailerOrderNumber: $retailerOrderNumber
      ebookid: $ebookid
    ) {
      randomKey
      retailerOrderNumber
    }
  }
`;

export default ADD_READING_REQUEST;
