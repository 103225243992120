import { gql } from '@apollo/client';

const TRANSFER_LOANS = gql`
  mutation transferLoans($countryCode: String!) {
    transferLoans(countryCode: $countryCode) {
      list {
        id
      }
      id
    }
  }
`;

export default TRANSFER_LOANS;
