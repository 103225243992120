import React from 'react';
import { useAnalytics } from 'use-analytics';
import { Helmet } from 'react-helmet';

import { useAuthState } from './components/auth/context';
import './App.css';
import Routes from './layout/routes';

const App = () => {
  const authState = useAuthState();
  const analytics = useAnalytics();

  const handleUnload = React.useCallback((e) => {
    analytics.track('b close app');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    // Setup event handlers
    let isEventListenerAttached = false;

    if (!isEventListenerAttached) {
      window.addEventListener('beforeunload', handleUnload);
      isEventListenerAttached = true;
    }
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
      isEventListenerAttached = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Wait until we if user is authenticated or not
  if (authState.isAuthenticated === null) return null;

  return (
    <Routes>
      <Helmet>
        <title></title>
      </Helmet>
    </Routes>
  );
};

export default App;
