// When using routes, prefix with / to get absolute ones
const ROUTES = {
  ROOT: '',
  ABOUT: 'about',
  ABOUT_ACCESSIBILITY: 'accessibility',
  ABOUT_CONTACT: 'contact',
  ABOUT_COOKIE_POLICY: 'cookie-policy',
  ABOUT_FAQ: 'faq',
  ABOUT_PRIVACY_POLICY: 'privacy-policy',
  ABOUT_SERVICE: 'service',
  ABOUT_TOS: 'terms-of-service',
  BOOK_LISTEN: 'listen',
  BOOK_READ: 'read',
  CI: 'grafiskprofil',
  CI_WEB_IMAGES: 'bilder-for-webb',
  CI_BOOKMARKS: 'bokmarken',
  CI_DIGITAL_SCREENS: 'digital-skarmar',
  CI_FLYERS: 'flyers',
  CI_FOLDERS: 'foldrar',
  CI_IMAGES: 'images',
  CI_IMAGE_BUTTON: 'knapp-for-webb',
  CI_LOGOTYPE: 'logotyp',
  CI_MANUAL: 'grafisk-manual',
  CI_POSTERS: 'affischer',
  CI_POSTCARDS: 'vykort',
  CI_CHILDREN_GAMES: 'pyssel',
  CI_PRESENTATIONS: 'presentation',
  CI_SOCIAL_MEDIA: 'social-media',
  DOWNLOADS: 'downloads',
  SHELF_ALL_TAB: 'all',
  SHELF_CHILDREN_TAB: 'children',
  SHELF_HOME_TAB: 'discover',
  SHELVES: 'shelves',
  COLLECTIONS_SUMMER_BOOK: 'sommarboken',
  TITLES: 'titles',
  USER: 'user',
  USER_PROFILE: 'profile',
};

export default ROUTES;
