import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useAuthState } from '../auth/context';
import Loader from '../loader';
// import SignIn from './signin';

const SecureRoute = ({ path, component, children, SignInComponent }) => {
  const authState = useAuthState();

  if (authState.isAuthenticated === null) return <Loader />;

  if (authState.isAuthenticated === true) {
    return (
      <Route path={path} component={component}>
        {children}
      </Route>
    );
  }
  // return SignInComponent ? <SignInComponent /> : <SignIn />;
  return <Redirect push to="/" />;
};

export default SecureRoute;
