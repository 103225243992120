import React, { Fragment, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@material-ui/core';
import {
  CheckCircleRounded as CheckIcon,
  Cancel as ClearIcon,
} from '@material-ui/icons';

import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppState } from '../app-context/src';
import ROUTES from '../../config/routes';

import { ConsentContext } from './';

const switchTrackerHeight = 26;
const switchTrackerWidth = 46;

const useStyles = makeStyles((theme) => ({
  buttonsArea: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-evenly',
    },
    flip: false,
    width: '100%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    textAlign: 'right',
  },
  button: {
    flip: false,
    marginRight: theme.spacing(4),
    minWidth: '8rem',
  },
  buttonConsent: {
    flip: false,
    backgroundColor: theme.palette.grape['600'],
  },
  buttonMoreInfo: {
    flip: false,
    fontWeight: 400,
    color: theme.palette.petrol['800'],
    backgroundColor: theme.palette.nougat['300'],
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.15)',
    },
  },
  formControlLabelRoot: {
    marginRight: '0',
  },
  switchRoot: {
    flip: false,
    width: switchTrackerWidth,
    height: switchTrackerHeight,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBaseMuted: {
    flip: false,
    padding: 1,
    color: theme.palette.grey['300'],
    '&$checked': {
      color: theme.palette.green['500'],
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 0,
      },
    },
  },
  switchBase: {
    flip: false,
    padding: 1,
    color: theme.palette.common.white,
    '&$checked': {
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.green['500'],
        opacity: 1,
      },
    },
  },
  track: {
    flip: false,
    borderRadius: switchTrackerHeight / 2,
    backgroundColor: theme.palette.grey['300'],
    opacity: 1,
  },
  checked: {},
  link: {
    color: theme.palette.primary.main,
    // textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const ConsentSettings = (props) => {
  const { handleDrawerClose } = props;
  const classes = useStyles();
  const [consents, setConsents] = useState(['mandatory', 'statistical']);
  const [consentModeAdvanced, setConsentModeAdvanced] = useState(false);
  const consentsContext = useContext(ConsentContext);
  const appState = useAppState();
  const intl = useIntl();

  const handleClose = () => {
    const expires = consents.length > 1 ? 180 : 30;
    consentsContext.set(consents, { expires });
    handleDrawerClose();
  };

  const hasConsent = (consentToChange) => {
    return consents.indexOf(consentToChange) > -1;
  };

  const addConsent = (consentToChange) => {
    setConsents([...consents, consentToChange]);
  };

  const removeConsent = (consentToChange) => {
    const indx = consents.indexOf(consentToChange);
    if (indx > -1) {
      const arr = [...consents];
      arr.splice(indx, 1);
      setConsents([...arr]);
    }
  };

  const toggleConsent = (consentToChange) => {
    if (consents.indexOf(consentToChange) === -1) {
      addConsent(consentToChange);
    } else {
      removeConsent(consentToChange);
    }
  };

  const handleChange = (consentToChange) => {
    toggleConsent(consentToChange);
  };

  return (
    <Fragment>
      <Typography component="h2" variant="h3">
        <FormattedMessage id={`consent.heading.${appState.fallbackLocale}`} />
      </Typography>

      <Box width="100%" display={{ xs: 'block', xl: 'flex' }}>
        <Box width={{ xs: '100%', xl: consentModeAdvanced ? '50%' : '100%' }}>
          <Typography paragraph variant="body2">
            <FormattedMessage id={`consent.intro.${appState.fallbackLocale}`} />
          </Typography>
        </Box>
        {consentModeAdvanced && (
          <Box width={{ xs: '100%', xl: '50%' }} pb={{ xs: 4, xl: 6 }}>
            <FormattedMessage
              id={`consent.policies.${appState.fallbackLocale}`}
              values={{
                privacyPolicy: (
                  <Link
                    to={`/${ROUTES.ABOUT}/${ROUTES.ABOUT_PRIVACY_POLICY}/${appState.fallbackLocale}`}
                    className={classes.link}
                  >
                    <Typography variant="body2" component="span">
                      <FormattedMessage
                        id={`about.privacy-policy.${appState.fallbackLocale}`}
                      />
                    </Typography>
                  </Link>
                ),
                cookiePolicy: (
                  <Link
                    to={`/${ROUTES.ABOUT}/${ROUTES.ABOUT_COOKIE_POLICY}/${appState.fallbackLocale}`}
                    className={classes.link}
                  >
                    <Typography variant="body2" component="span">
                      <FormattedMessage
                        id={`about.cookie-policy.${appState.fallbackLocale}`}
                      />
                    </Typography>
                  </Link>
                ),
              }}
            />
          </Box>
        )}
      </Box>

      {consentModeAdvanced && (
        <Fragment>
          <Grid container spacing={1}>
            <Grid item xs={2} lg={1} xl={1}>
              <Box>
                <FormControlLabel
                  value="start"
                  classes={{ root: classes.formControlLabelRoot }}
                  control={
                    <Switch
                      name="mandatory"
                      disableRipple
                      checkedIcon={<CheckIcon />}
                      icon={<ClearIcon />}
                      checked={true}
                      value={true}
                      required
                      classes={{
                        root: classes.switchRoot,
                        switchBase: classes.switchBaseMuted,
                        track: classes.track,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label={''}
                  labelPlacement="end"
                />
              </Box>
            </Grid>
            <Grid item xs={10} lg={5} xl={5}>
              <Box pt={1} px={4}>
                <Typography component="h3" variant="h3">
                  <FormattedMessage
                    id={`consent.cookies-mandatory.heading.${appState.fallbackLocale}`}
                  />
                </Typography>
                <Typography paragraph variant="body2">
                  <FormattedMessage
                    id={`consent.cookies-mandatory.description.${appState.fallbackLocale}`}
                  />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} lg={1} xl={1}>
              <Box fullWidth textAlign="right">
                <FormControlLabel
                  value="start"
                  classes={{ root: classes.formControlLabelRoot }}
                  control={
                    <Switch
                      name="statistical"
                      disableRipple
                      checkedIcon={<CheckIcon />}
                      icon={<ClearIcon />}
                      checked={hasConsent('statistical')}
                      onChange={(e) => handleChange('statistical')}
                      value={hasConsent('statistical')}
                      required
                      classes={{
                        root: classes.switchRoot,
                        switchBase: classes.switchBase,
                        track: classes.track,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label={''}
                  labelPlacement="end"
                />
              </Box>
            </Grid>
            <Grid item xs={10} lg={5} xl={5}>
              <Box pt={1} px={4}>
                <Typography component="h3" variant="h3">
                  <FormattedMessage
                    id={`consent.cookies-stats.heading.${appState.fallbackLocale}`}
                  />
                </Typography>
                <Typography paragraph variant="body2">
                  <FormattedMessage
                    id={`consent.cookies-stats.description.${appState.fallbackLocale}`}
                  />
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Fragment>
      )}

      <Box className={classes.buttonsArea}>
        {!consentModeAdvanced && (
          <>
            <Button
              data-testid="consent-show-advanced"
              className={`${classes.button} ${classes.buttonMoreInfo}`}
              variant="contained"
              onClick={() => {
                setConsents(['mandatory']); // Remove all other but the mandatory
                setConsentModeAdvanced(true);
              }}
              tabIndex={0}
              aria-label={intl.formatMessage({
                id: `consent.button-label.more-info.${appState.fallbackLocale}`,
              })}
            >
              <FormattedMessage
                id={`consent.button.more-info.${appState.fallbackLocale}`}
              />
            </Button>
            <Button
              data-testid="consent-accept"
              variant="contained"
              color="secondary"
              onClick={handleClose}
              className={`${classes.button} ${classes.buttonConsent}`}
              tabIndex={0}
              aria-label={intl.formatMessage({
                id: `consent.button-label.consent-all.${appState.fallbackLocale}`,
              })}
            >
              <FormattedMessage
                id={`consent.button.consent-all.${appState.fallbackLocale}`}
              />
            </Button>
          </>
        )}
        {consentModeAdvanced && (
          <Button
            data-testid="consent-accept"
            variant="contained"
            color="secondary"
            onClick={handleClose}
            className={`${classes.button} ${classes.buttonConsent}`}
            tabIndex={0}
            aria-label={intl.formatMessage({
              id: `consent.button-label.consent.${appState.fallbackLocale}`,
            })}
          >
            <FormattedMessage
              id={`consent.button.consent.${appState.fallbackLocale}`}
            />
          </Button>
        )}
      </Box>
    </Fragment>
  );
};

ConsentSettings.propTypes = {
  handleDrawerClose: PropTypes.func.isRequired,
};

export default ConsentSettings;
