import { gql } from '@apollo/client';

const GET_LOANS = gql`
  query GetLoans {
    user {
      id
      loans {
        id
        list {
          id
          title
          downloadurl
          retailerOrderNumber
          internalOrderNumber
        }
      }
    }
  }
`;

export default GET_LOANS;
