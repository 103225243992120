import React from 'react';
import { useFirebase } from './../firebase-context';
import REMOTE_CONFIG from './settings.remote-config';

const FeatureFlagsContext = React.createContext({});

const FeatureFlagsProvider = ({ children, initialFlagValues = {} }) => {
  const [flags, setFlags] = React.useState({
    ...REMOTE_CONFIG,
    ...initialFlagValues,
  });

  const { firebase } = useFirebase();
  const remoteConfig = firebase.remoteConfig();

  // For development only
  // if (process.env.NODE_ENV === 'development') {
  //   remoteConfig.settings = {
  //     minimumFetchIntervalMillis: 2 * 60 * 1000  ,
  //   };
  // }

  // Set config values on mount
  React.useEffect(() => {
    remoteConfig.defaultConfig = {
      ...REMOTE_CONFIG,
      ...initialFlagValues,
    };

    // Do not fetch remote config during tests
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    remoteConfig
      .fetchAndActivate()
      .then((activated) => {
        // if (!activated) {
        //   console.log('not activated', activated);
        // }
        return remoteConfig.getAll();
      })
      .then((remoteFlags) => {
        const newFlags = {
          ...flags,
        };

        for (const [key, val] of Object.entries(remoteFlags)) {
          const strVal = val.asString();
          try {
            newFlags[key] = JSON.parse(strVal);
          } catch (e) {
            newFlags[key] = strVal;
          }
        }
        setFlags(newFlags);
      })
      .catch((error) => {
        console.error('Remote config error', error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FeatureFlagsContext.Provider value={flags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export default FeatureFlagsProvider;

export const useFlags = () => React.useContext(FeatureFlagsContext);
