import Cookies from 'js-cookie';

/**
 * Persist array consents under in a cookie
 * @param {string} cookieName The name of the cookie
 * @param {string[]} consents Array of consents
 * @param {object} cookieOptions Object with cookie options
 * @returns {undefined} Undefined
 */
export const persistConsentsInCookie = (
  cookieName,
  consents,
  cookieOptions = {}
) => {
  Cookies.set(cookieName, JSON.stringify(consents), { ...cookieOptions });
};

/**
 * Read consents from a cookie
 * @param {string} cookieName The name of the cookie
 * @returns {string[]} Array of consents
 */
export const readConsentsFromCookie = (cookieName) => {
  const cookie = Cookies.get(cookieName);
  return cookie ? JSON.parse(cookie) : undefined;
};
