import { gql } from '@apollo/client';

const GET_USER_NEWSLETTERS = gql`
  query getUserNewsletters {
    user {
      id
      newsletters {
        ... on UserNewsletter {
          status
          statusCode
          list_id
          name
        }
        ... on UserNewsletterError {
          status
          statusCode
          list_id
          name
        }
      }
    }
  }
`;

export default GET_USER_NEWSLETTERS;
