import { hostLanguages } from '../config/active-translations';

const getBrowserPreferredLanguage = () => {
  let lang = window.navigator.languages ? window.navigator.languages[0] : null;
  lang =
    lang ||
    window.navigator.language ||
    window.navigator.browserLanguage ||
    window.navigator.userLanguage;

  let shortLang = lang;
  if (shortLang.indexOf('-') > -1) shortLang = shortLang.split('-')[0];

  if (shortLang.indexOf('_') > -1) shortLang = shortLang.split('_')[0];
  return shortLang;
};

/**
 * Get domain suffix, ie se, no, com, localhost...
 */
const domainSuffix = () => {
  // if (window.location.hostname === 'localhost') return 'se';
  return window.location.hostname
    .substring(
      window.location.hostname.lastIndexOf('.') + 1,
      window.location.hostname.length
    )
    .toLowerCase();
};

/**
 * Get country from domain suffix
 */
const domainCountry = () => {
  let retval;
  switch (domainSuffix()) {
    case 'no':
      retval = 'no';
      break;
    default:
      retval = 'se';
  }
  return retval;
};

/**
 * Infer default locale from domain
 */
const domainLocale = () => {
  let retval;
  switch (domainCountry()) {
    case 'no':
      retval = 'no';
      break;
    case 'se':
      retval = 'sv';
      break;
    default:
      retval =
        hostLanguages.indexOf(getBrowserPreferredLanguage()) > -1
          ? getBrowserPreferredLanguage()
          : 'sv';
  }
  return retval;
};

export { domainCountry, domainLocale, domainSuffix };
