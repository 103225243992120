import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  centered: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const Loader = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.centered}>
      <CircularProgress />
    </div>
  );
};

export default Loader;
