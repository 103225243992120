// Specific settings for each host country
import { path } from 'ramda';

/**
 * shelfName - Mapping language groups/codes to country specific abbreviations
 *
 * @param {*} countryCode ISO 639-1 code
 */
class CountrySettings {
  constructor(countryCode) {
    this.countryCode = countryCode;
    this.settings = this.getSettings(this.countryCode);
  }

  // shelfNames are sorted by the order specified below, hence the order is in alphabetic
  // order of the shelf names, ie albanian, arabic, bosnian...
  static settings = {
    no: {
      social: {
        facebook: {
          url: 'https://www.facebook.com/verdensbiblioteket.no',
        },
      },
      tracking: {
        ga_id: process.env.REACT_APP_TRACKING_NO_GA_ID,
        piwikpro_id: process.env.REACT_APP_TRACKING_NO_PIWIKPRO,
      },
      shelfNames: {
        sqi: 'alb', // Albanian
        amh: 'amh',
        ara: 'ara', // Arabic
        syr: 'ass', // Assyric
        hbs: 'bks', // Bosnian, Kroatian, Serbian
        kur: 'kur', // Kurdic
        fas: 'per', // Persian
        pol: 'pol', // Polish
        som: 'som', // Somali
        tha: 'tha', // Thai
        tir: 'tig', // Tigrinya
        tur: 'tyr', // Turkish
        ukr: 'ukr', // Ukranian
      },
      collections: {
        summerbook: false,
      },
    },
    se: {
      social: {
        facebook: {
          url: 'https://facebook.com/varldensbibliotek.se',
        },
        instagram: {
          url: 'https://instagram.com/varldensbibliotek.se',
        },
      },
      tracking: {
        ga_id: process.env.REACT_APP_TRACKING_SE_GA_ID,
        piwikpro_id: process.env.REACT_APP_TRACKING_SE_PIWIKPRO,
      },
      shelfNames: {
        sqi: 'alb',
        amh: 'amh',
        ara: 'ara',
        syr: 'ass',
        hbs: 'bks',
        kur: 'kur',
        fas: 'per',
        pol: 'pol',
        som: 'som',
        tha: 'tha',
        tir: 'tig',
        tur: 'tur',
        ukr: 'ukr',
      },
      collections: {
        summerbook: true,
      },
    },
  };

  // Check if country is defined
  hasCountry(countryCode) {
    return !!CountrySettings.settings.hasOwnProperty(countryCode);
  }

  hasSummerbook() {
    try {
      return this.settings.collections.summerbook;
    } catch (error) {
      console.error(
        `Couldn't find setting for summerbook collection. \n ${error}`
      );
    }
    return false;
  }

  getSettings(countryCode) {
    if (CountrySettings.settings.hasOwnProperty(countryCode)) {
      return CountrySettings.settings[countryCode];
    }
    // Issue warning
    if (process.env.NODE_ENV !== 'development') {
      console.log(
        '⚠️ Could not deduce countryCode from hostname, hence could not find country settings.'
      );
    }
    // Fallback
    this.countryCode = 'se';
    return CountrySettings.settings[this.countryCode];
  }

  getShelfCode(langGroup) {
    if (this.settings.shelfNames && this.settings.shelfNames[langGroup]) {
      return this.settings.shelfNames[langGroup];
    }
    // Fallback to lang group
    return langGroup;
  }

  getTrackingGA_ID() {
    const trackingId = path(['tracking', 'ga_id'], this.settings);
    if (!trackingId) {
      console.log('⚠ Missing GA configuration');
    }
    return trackingId;
  }

  getTrackingPiwikPro_ID() {
    var ppTrackingId;
    ppTrackingId = path(['tracking', 'piwikpro_id'], this.settings);
    if (!ppTrackingId) {
      console.log('⚠ Missing Piwik Pro configuration');
    }
    return ppTrackingId;
  }

  getAllShelfCodes() {
    return this.settings.shelfNames;
  }
}

export default CountrySettings;
