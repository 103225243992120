import React, { useContext, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFirebase } from '../firebase-context';

const AuthContext = React.createContext();

const AuthContextProvider = ({ children, initialValues = {} }) => {
  const firebaseState = useFirebase();
  const signInWithGoogle = async () => {
    const provider = new firebaseState.firebase.auth.GoogleAuthProvider();
    return firebaseState.firebase.auth().signInWithPopup(provider);
  };

  const signInWithEmailAndPassword = async (username, password) => {
    try {
      return firebaseState.firebase
        .auth()
        .signInWithEmailAndPassword(username, password);
    } catch (error) {
      console.error('Error: Sign in with email', error);
      return error;
    }
  };

  const signUpWithEmailAndPassword = async (username, password) => {
    try {
      return firebaseState.firebase
        .auth()
        .createUserWithEmailAndPassword(username, password);
    } catch (error) {
      console.error('Error: Sign up with email', error);
      return error;
    }
  };
  const signOut = async () => {
    try {
      await firebaseState.firebase.auth().signOut();
    } catch (error) {
      return error;
    }
  };

  const defaultState = useMemo(
    () => ({
      isAuthenticated: null,
      signInWithGoogle,
      signInWithEmailAndPassword,
      signUpWithEmailAndPassword,
      signOut,
      token: null,
      ...initialValues, // Assign values directly when rendering provider, useful in tests
    }),
    [
      initialValues,
      signInWithGoogle,
      signInWithEmailAndPassword,
      signUpWithEmailAndPassword,
      signOut,
    ]
  );

  const [authState, setAuthState] = useState(defaultState);

  useEffect(() => {
    if (authState.isAuthenticated !== null) return;

    // Track changes of firebase auth state
    const onAuthStateChange = (callback, state) => {
      firebaseState.firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          // Pick out user data
          const providerId = user.providerData.length
            ? user.providerData[0].providerId
            : null;
          const userData = {
            uid: user.uid,
            email: user.email,
            phonenumber: user.phoneNumber,
            displayName: user.displayName,
            providerId,
          };
          // Get token and add to authState
          user.getIdToken().then((token) => {
            callback({
              ...defaultState,
              isAuthenticated: true,
              ...userData,
              token,
            });
          });
        } else {
          callback({ ...state, isAuthenticated: false });
        }
      });
    };

    const unsubscribe = onAuthStateChange(setAuthState, authState);
    // Cleanup on unmount
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [
    firebaseState.firebase,
    authState,
    authState.isAuthenticated,
    defaultState,
  ]);

  return (
    <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.node,
};

const AuthContextConsumer = AuthContext.Consumer;
const useAuthState = () => useContext(AuthContext);

export default AuthContext;
export { AuthContextConsumer, AuthContextProvider, useAuthState };
