/**
 * Locale settings
 *
 *  code - language code according to ISO 639-1
 *  rtl - if the language is written right to left or not
 *  name - the native language name, not used
 *  iso_639_1 - language code according to ISO 639-1, necessary for react-intl
 *  iso_639_2 - three letter language code according to ISO 639-2/3, not used at the moment
 */

const localeSettings = [
  {
    code: 'am',
    rtl: false,
    // iso_639_1: 'am',
    // iso_639_2: 'amh',
  },
  {
    code: 'ar',
    rtl: true,
    // name: 'العربية',
    // iso_639_1: 'ar',
    // iso_639_2: 'ara',
  },
  {
    code: 'bs',
    rtl: false,
    // name: 'bosanski',
    // iso_639_1: 'bs',
    // iso_639_2: 'bos',
  },
  {
    code: 'en',
    rtl: false,
    // name: 'english',
    // iso_639_1: 'en',
    // iso_639_2: 'eng',
  },
  {
    code: 'fa',
    rtl: true,
  },
  {
    code: 'he',
    rtl: true,
  },
  {
    code: 'hr',
    rtl: false,
  },
  {
    code: 'ku',
    rtl: false,
  },
  {
    code: 'no',
    rtl: false,
  },
  {
    code: 'pl',
    rtl: false,
  },
  {
    code: 'so',
    rtl: false,
  },
  {
    code: 'sq',
    rtl: false,
  },
  {
    code: 'sr',
    rtl: false,
  },
  {
    code: 'sv',
    rtl: false,
  },
  {
    code: 'sy', // neo-aramaic/syriac/assyrian... has no language code in ISO-639-1, so this is made as the code was free
    rtl: true,
  },
  {
    code: 'ti',
    rtl: false,
  },
  {
    code: 'th',
    rtl: false,
  },
  {
    code: 'tr',
    rtl: false,
  },
  {
    code: 'uk',
    rtl: false,
  },
];

export default localeSettings;
