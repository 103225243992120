import { gql } from '@apollo/client';

const GET_USER_TRACKING_ID = gql`
  query GetTid {
    user {
      id
      tracking {
        id
      }
    }
  }
`;

export default GET_USER_TRACKING_ID;
