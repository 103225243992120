import { gql } from '@apollo/client';

const ADD_USER_CONSENT = gql`
  mutation addUserConsent(
    $cookieConsent: Boolean!
    $privacyConsent: Boolean!
    $tosConsent: Boolean!
  ) {
    addUserConsent(
      cookieConsent: $cookieConsent
      privacyConsent: $privacyConsent
      tosConsent: $tosConsent
    ) {
      cookiePolicy
      privacyPolicy
      termsOfService
    }
  }
`;

export default ADD_USER_CONSENT;
