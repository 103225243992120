import { gql } from '@apollo/client';

const ONBOARD_USER = gql`
  query onboardUser($countryCode: String!) {
    user {
      onboarding(countryCode: $countryCode) {
        status
      }
    }
  }
`;

export default ONBOARD_USER;
