import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Drawer } from '@material-ui/core';
import { useIntl } from 'react-intl';

import BaseWidth from '../base-width';
import ConsentSettings from './consent-settings';
import { ConsentContext } from './';
import { useAppState } from '../app-context/src/index';
import { getDirectionByLangCode } from '../../helpers/locales';

const drawerWidth = '100%';

const useStyles = makeStyles((theme) => {
  return {
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      [theme.breakpoints.up('xl')]: {
        paddingBottom: theme.spacing(6),
        borderRadius: theme.spacing(3),
      },
    },
    drawerContent: {
      [theme.breakpoints.up('lg')]: {
        width: '800px',
      },
      [theme.breakpoints.up('xl')]: {
        borderRadius: theme.spacing(1),
      },
      borderTop: `${theme.spacing(1)}px solid ${theme.palette.grape['600']}`,
      display: 'flex',
      flexDirection: 'column',
      boxShadow:
        '0px 0px 4px -1px rgb(0 0 0 / 20%), 0px -1px 5px 0px rgb(0 0 0 / 14%), 0px 0px 10px 0px rgb(0 0 0 / 12%)',
      [theme.breakpoints.up('lg')]: {
        boxShadow:
          '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);',
      },
      justifyContent: 'flex-start',
      backgroundColor: theme.palette.common.white,
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
    },
  };
});

const ConsentDrawer = () => {
  const classes = useStyles();
  const consentsContext = useContext(ConsentContext);
  const consents = consentsContext.get();
  const [open, setOpen] = React.useState(!Array.isArray(consents));
  const appState = useAppState();
  const intl = useIntl();

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      data-testid="consent-drawer"
      className={classes.drawer}
      variant="persistent"
      anchor="bottom"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
      dir={getDirectionByLangCode(appState.fallbackLocale)}
      role="region"
      aria-label={intl.formatMessage({
        id: `consent.heading.${appState.fallbackLocale}`,
      })}
      tabIndex={-1}
      aria-hidden={!open}
      focusable={!open}
    >
      <BaseWidth disableGutters disablePadding className={classes.center}>
        <Box
          width="100%"
          className={classes.drawerContent}
          px={{ xs: 5, sm: 5, md: 5, lg: 9, xl: 9 }}
          pt={{ xs: 4, sm: 4, md: 4, lg: 7, xl: 7 }}
        >
          <ConsentSettings handleDrawerClose={handleDrawerClose} />
        </Box>
      </BaseWidth>
    </Drawer>
  );
};

export default ConsentDrawer;
