import locales from '../config/locale-settings';

const isRtl = (langCode) => {
  const selectedLocale = locales.filter((l) => l.code === langCode);
  return selectedLocale.length > 0 && selectedLocale[0].rtl;
};

const getDirectionByLangCode = (langCode) => (isRtl(langCode) ? 'rtl' : 'ltr');
const getISO639_1 = (langCode) =>
  locales.filter((l) => l.code === langCode)[0].code;

export { isRtl, getDirectionByLangCode, getISO639_1 };
export default locales;
