import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import Helmet from 'react-helmet';
import { getISO639_1 } from '../../../helpers/locales';
import { useAppState } from '../../app-context/src';

const messagesCache = {};

const getMessages = (locale) => {
  if (messagesCache[locale]) {
    return messagesCache[locale];
  }
  // Suspense is based on ErrorBoundary
  // throwing a promise will cause <SomeLoadingComponent /> to render until the promise resolves
  throw loadMessages(locale);
};

const loadMessages = async (locale) => {
  // dynamic import syntax tells webpack to split this module into its own chunk
  const messages = await import(`./../../../translations/${locale}.json`);
  messagesCache[locale] = messages;
  return messages;
};

const AsyncIntlProvider = ({ children }) => {
  const appState = useAppState();
  const locale = appState.currentLocale;
  const messages = getMessages(locale);

  return (
    <IntlProvider locale={getISO639_1(locale)} messages={messages}>
      <Helmet
        htmlAttributes={{
          lang: locale,
          dir: appState.direction,
        }}
      />
      {children}
    </IntlProvider>
  );
};

AsyncIntlProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AsyncIntlProvider;
