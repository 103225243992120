import { gql } from '@apollo/client';

const GET_USER_CONSENT = gql`
  query GetUserConsent {
    user {
      consent {
        privacyPolicy
        cookiePolicy
        termsOfService
      }
    }
  }
`;

export default GET_USER_CONSENT;
