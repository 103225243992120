import React, { useContext } from 'react';
import Analytics from 'analytics';
import { AnalyticsProvider } from 'use-analytics';
import CountrySettings from './../../config/country-settings';
import { domainCountry } from './../../helpers/domain';
import TrackUserID from './track-userid';
import { ConsentContext } from '../consent';
import piwikProPlugin from './piwik-pro-plugin';

const countrySettings = new CountrySettings(domainCountry());

const piwikProSettings = () =>
  piwikProPlugin({
    enabled: false,
    containerUrl: 'https://varldensbibliotek.containers.piwik.pro',
    containerId: countrySettings.getTrackingPiwikPro_ID(),
    customDimensions: {
      label: 1,
      book: 2,
      mediatype: 3,
      bookTitle: 4,
      authors: 5,
      genre: 6,
      targetGroup: 7,
      ages: 8,
      bookLang: 9,
      publisher: 10,
      pubDate: 11,
      category: 12,
      bookshelf: 13,
      authtype: 14,
      source: 15,
    },
  });

const analyticsSettings = {
  app: `vb-${process.env.REACT_APP_DIST_ENV}`,
  plugins: [piwikProSettings()],
};

const analytics = Analytics(analyticsSettings);

const Tracking = ({ children }) => {
  const consentContext = useContext(ConsentContext);

  const updateAnonTrackingID = (trackingId) => {
    if (consentContext.has('statistical')) {
      analytics.identify(trackingId);
    } else {
      console.log(
        'Anon tracking ID updated, but statistical consent not given'
      );
    }
  };

  React.useEffect(() => {
    if (consentContext.has('statistical')) {
      analytics.plugins.enable('piwikPro');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (consentContext.has('statistical')) {
      analytics.plugins.enable('piwikPro');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consentContext]);

  return (
    <AnalyticsProvider instance={analytics}>
      <TrackUserID onTIDChanged={updateAnonTrackingID} />
      {children}
    </AnalyticsProvider>
  );
};

export default Tracking;
