import { gql } from '@apollo/client';

const VALID_REQUEST_ORIGIN = gql`
  query validRequestOrigin {
    public {
      validRequestOrigin
    }
  }
`;

export default VALID_REQUEST_ORIGIN;
