import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const BaseWidth = ({
  children,
  disableGutters,
  maxWidth,
  disablePadding = false,
  className = '',
}) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      paddingLeft: disablePadding ? 0 : theme.spacing(2),
      paddingRight: disablePadding ? 0 : theme.spacing(2),

      [theme.breakpoints.up('sm')]: {
        paddingLeft: disablePadding ? 0 : theme.spacing(4),
        paddingRight: disablePadding ? 0 : theme.spacing(4),
      },

      [theme.breakpoints.up('lg')]: {
        paddingLeft: disablePadding ? 0 : theme.spacing(5),
        paddingRight: disablePadding ? 0 : theme.spacing(5),
      },

      [theme.breakpoints.up('xl')]: {
        paddingLeft: disablePadding ? 0 : theme.spacing(6),
        paddingRight: disablePadding ? 0 : theme.spacing(6),
      },
    },
  }));

  const classes = useStyles();

  return (
    <Container
      disableGutters={disableGutters}
      maxWidth={maxWidth}
      className={`${classes.container} ${className}`}
    >
      {children}
    </Container>
  );
};

BaseWidth.defaultProps = {
  disableGutters: true,
  maxWidth: 'xl',
};

BaseWidth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BaseWidth;
