import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { readConsentsFromCookie, persistConsentsInCookie } from './cookie';

const ConsentContext = React.createContext();

export const ConsentContextProvider = ({
  cookieName,
  cookieOptions = {},
  children,
}) => {
  const readConsents = readConsentsFromCookie(cookieName);
  const [currentConsents, setCurrentConsents] = useState(readConsents);

  const updateConsents = (newConsents, customCookieOptions = {}) => {
    // console.log('updateConsents', cookieName, newConsents, cookieOptions);
    persistConsentsInCookie(cookieName, newConsents, {
      ...cookieOptions,
      ...customCookieOptions,
    });
    setCurrentConsents(newConsents);
  };

  const contextValues = {
    set: (consentsToAdd, customCookieOptions = {}) => {
      updateConsents(consentsToAdd, {
        ...cookieOptions,
        ...customCookieOptions,
      });
    },
    has: (consent) => {
      if (currentConsents && Array.isArray(currentConsents)) {
        return currentConsents.indexOf(consent) > -1;
      }
      return false;
    },
    get: () => currentConsents,
    // clear: () => {
    //   updateConsents([]);
    // },
    // remove: (consentsToRemove, cookieOptions = {}) => {
    //   updateConsents(
    //     currentConsents.filter(c => !consentsToRemove.includes(c)),
    //     cookieOptions
    //   );
    // },
  };

  return (
    <ConsentContext.Provider value={contextValues}>
      {children}
    </ConsentContext.Provider>
  );
};

ConsentContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const ConsentContextConsumer = ConsentContext.Consumer;
export default ConsentContext;
