/**
 * Active translations, ie translations that may be used for the GUI
 */

// Languages of the hosts, ie Sweden, Norway...
export const hostLanguages = ['sv', 'no'];

const activeTranslations = [...hostLanguages];

export default activeTranslations;
