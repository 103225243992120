import React from 'react';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';

const BaseRouter = ({ children, initialBrowserHistory = {} }) => (
  <Router history={{ ...createBrowserHistory(), ...initialBrowserHistory }}>
    {children}
  </Router>
);

export default BaseRouter;
