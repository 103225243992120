import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorOutline as ErrorOutlineIcon } from '@material-ui/icons';
const useStyles = makeStyles((theme) => ({
  centered: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  icon: {
    width: '8rem',
    height: '8rem',
    transform: 'rotateZ(45deg)',
  },
}));

const Error404 = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.centered}>
      <ErrorOutlineIcon className={classes.icon} />
      <div>Page could not be found</div>
    </div>
  );
};

export default Error404;
