import React from 'react';
import { useAuthState } from '../auth/context';
import { useLazyQuery } from '@apollo/client';
import { GET_USER_TRACKING_ID } from '../../graphql/gql';
import { path } from 'ramda';

/**
 * Get unique trackingid based on userid
 */
const TrackUserID = ({ onTIDChanged }) => {
  const authState = useAuthState();
  const [trackingId, setTrackingId] = React.useState(null);
  const [getTrackingId, { data }] = useLazyQuery(GET_USER_TRACKING_ID);

  React.useEffect(() => {
    if (authState.isAuthenticated === null) {
      // Not yet authenticated
      return;
    }

    if (trackingId) {
      // Tracking ID already set
      return;
    }

    if (data === undefined) {
      // Get anonymous tracking id
      getTrackingId();
      return;
    } else if (data?.user?.tracking?.id) {
      // Assign anonymous trackingId to state
      const tid = path(['user', 'tracking', 'id'], data);
      setTrackingId(tid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.isAuthenticated, authState.token, authState.uid, data]);

  // Pick up state changes to trackingId, will be triggered useEffect above if user is signed in
  React.useEffect(() => {
    if (trackingId) {
      onTIDChanged(trackingId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingId]);

  return null;
};

export default React.memo(TrackUserID);
